import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Flag from 'react-world-flags';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'gatsby';
import { parseLinkForFlagButton } from '../../gatsby/utils';
import locales from '../../gatsby/locales';

const styles = () => ({
  menuContainer: {
    paddingTop: 0,
    paddingBottom: 0
  },
  textDecorationNone: {
    color: 'unset',
    'text-decoration': 'none'
  }
});

const LanguageButton = ({ classes, pathname, locale, handleLocale }) => (
  <List className={classes.menuContainer}>
    {locales &&
      Object.keys(locales).map(local => (
        <Link
          key={uuid.v4()}
          onClick={() =>
            handleLocale(locales[local].locale, locales[local].flag)
          }
          to={parseLinkForFlagButton(locale, locales[local].path, pathname)}
          className={classes.textDecorationNone}
        >
          <ListItem button divider>
            <ListItemIcon>
              <Flag code={locales[local].flag} width="25" height="20" />
            </ListItemIcon>
            <ListItemText inset primary={locales[local].language} />
          </ListItem>
        </Link>
      ))}
  </List>
);

LanguageButton.propTypes = {
  handleLocale: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    menuContainer: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles)(LanguageButton);
