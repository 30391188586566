import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  overlapping: {
    [theme.breakpoints.up('md')]: {
      'margin-top': '-90px'
    }
  }
});

const Container = ({
  children,
  classes,
  style,
  size,
  background,
  isCenter,
  hasOverlap,
  ...rest
}) => (
  <Grid
    container
    item
    direction="row"
    justify="center"
    // Y-axis center
    alignItems={isCenter ? 'center' : 'stretch'}
    // container overlap top
    className={hasOverlap ? classes.overlapping : ''}
    // inline styles
    style={{
      // vertical size of the container
      ...(size && size === 33 ? { minHeight: '33vh' } : null),
      ...(size && size === 50 ? { minHeight: '50vh' } : null),
      ...(size && size === 66 ? { minHeight: '66.6vh' } : null),
      ...(size && size === 100 ? { minHeight: '100vh' } : null),
      // background
      ...(background ? { background } : null),
      // rest of styles passed in
      ...style
    }}
    {...rest}
  >
    <Grid item xs={11} sm={11} md={10} lg={10} xl={8}>
      {children}
    </Grid>
  </Grid>
);

Container.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({ overlapping: PropTypes.string.isRequired })
    .isRequired,
  style: PropTypes.shape(),
  size: PropTypes.number,
  background: PropTypes.string,
  isCenter: PropTypes.bool,
  hasOverlap: PropTypes.bool
};

Container.defaultProps = {
  children: [],
  style: {},
  size: 0,
  background: '',
  isCenter: false,
  hasOverlap: false
};

export default withStyles(styles)(Container);
