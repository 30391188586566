import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Collapse from '@material-ui/core/Collapse';

import CollapsibleList from './CollapsibleList';

const styles = theme => ({
  anchor: {
    margin: `${theme.spacing(1, 0)}`,
    color: '#b2dfde',
    'text-decoration': 'none',
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    '&:hover': {
      color: '#ffffff'
    }
  }
});

class Accordion extends Component {
  state = {
    open: false
  };

  handleExpandClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, title, menuData } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <button
          type="button"
          className={classes.anchor}
          onClick={this.handleExpandClick}
        >
          {title}
        </button>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CollapsibleList menuData={menuData} />
        </Collapse>
      </React.Fragment>
    );
  }
}

Accordion.propTypes = {
  classes: PropTypes.shape({
    anchor: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired,
  menuData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default withStyles(styles)(Accordion);
