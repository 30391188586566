const GOOGLE_SCRIPT_URL =
  'https://script.google.com/macros/s/AKfycbxoo0MYrw6IDUr6J2gzaKf0UJCLC3knV1TxvXSO9nExtvRjjbE/exec';

const encodeParams = p =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&');

export default async emailData => {
  const options = {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  };
  const urlWithParams = `${GOOGLE_SCRIPT_URL}?${encodeParams(emailData)}`;

  const response = await fetch(urlWithParams, options);
  const result = await response.json(response);

  return result;
};
