import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import Container from './Container';
import Link from '../components/link/Link';

const styles = theme => ({
  container: {
    padding: '24px 0',
    'background-color': theme.palette.primary.dark
  },
  blockStart: {
    [theme.breakpoints.down('sm')]: {
      'text-align': 'center'
    },
    [theme.breakpoints.up('sm')]: {
      'text-align': 'start'
    }
  },
  blockCenter: {
    [theme.breakpoints.down('sm')]: {
      'text-align': 'center'
    },
    [theme.breakpoints.up('sm')]: {
      'text-align': 'center'
    }
  },
  blockEnd: {
    [theme.breakpoints.down('sm')]: {
      'text-align': 'center'
    },
    [theme.breakpoints.up('sm')]: {
      'text-align': 'end'
    }
  },
  textBlock: {
    display: 'inline-flex',
    'align-items': 'center',
    'vertical-align': 'middle'
  },
  text: {
    '&&': {
      color: '#b2dfde'
    }
  },
  anchor: {
    color: '#b2dfde',
    'text-decoration': 'none',
    '&:hover': {
      color: '#ffffff'
    }
  }
});

class FootNote extends React.Component {
  state = {
    footerNoteContent: []
  };

  componentDidMount() {
    const { data } = this.props;
    const { allPrismicNavbar } = data;
    const { nodes } = allPrismicNavbar;
    const footNoteText = [];

    nodes.forEach(dataForLang => {
      const { lang, data: headingsForLang } = dataForLang;
      const {
        foot_note_left_heading,
        foot_note_center_heading
      } = headingsForLang;

      footNoteText.push({
        lang,
        foot_note_left_heading,
        foot_note_center_heading
      });
    });

    this.setState({ footerNoteContent: footNoteText });
  }

  render() {
    const { classes, localeContext } = this.props;
    const { footerNoteContent } = this.state;

    const { locale } = localeContext;

    const foundContentForLanguage = footerNoteContent.find(
      ({ lang }) => lang === locale
    );

    if (!foundContentForLanguage) {
      return null;
    }

    const {
      foot_note_left_heading,
      foot_note_center_heading
    } = foundContentForLanguage;

    return (
      <Container className={classes.container}>
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={12} lg sm={3} className={classes.blockStart}>
            <Typography className={classes.text}>
              <Link className={classes.anchor} to="/">
                {foot_note_left_heading}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} lg sm className={classes.blockCenter}>
            <div className={classes.textBlock}>
              <Typography className={classes.text}>
                {foot_note_center_heading}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} lg sm={3} className={classes.blockEnd}>
            <Typography className={classes.text}>
              <Link className={classes.anchor} to="https://mkit.io">
                MK IT
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

FootNote.propTypes = {
  data: PropTypes.shape({
    allPrismicNavbar: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    }).isRequired
  }).isRequired,
  localeContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    changeLocale: PropTypes.func.isRequired
  }).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    blockStart: PropTypes.string.isRequired,
    blockCenter: PropTypes.string.isRequired,
    blockEnd: PropTypes.string.isRequired,
    textBlock: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    anchor: PropTypes.string.isRequired
  }).isRequired
};

const FootNoteWithStyles = withStyles(styles)(FootNote);

export default props => (
  <StaticQuery
    query={graphql`
      query FootNote {
        allPrismicNavbar {
          nodes {
            lang
            data {
              foot_note_left_heading
              foot_note_center_heading
            }
          }
        }
      }
    `}
    render={data => <FootNoteWithStyles data={data} {...props} />}
  />
);
