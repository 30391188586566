import uuid from 'uuid';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Link from '../link/Link';

const styles = () => ({
  menuContainer: {
    paddingTop: 0,
    paddingBottom: 0
  },
  unorganisedLi: {
    paddingLeft: 'unset',
    color: '#b2dfde',
    'list-style-type': 'none',
    'text-decoration': 'none'
  },
  anchor: {
    color: '#b2dfde',
    'text-decoration': 'none',
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    '&:hover': {
      color: '#ffffff'
    }
  },
  expandIconStyle: {
    marginBottom: '-6px'
  }
});

class CollapsibleList extends Component {
  state = {};

  handleClick(item) {
    this.setState(prevState => ({ [item]: !prevState[item] }));
  }

  handler(menuData) {
    const { classes } = this.props;
    const { state } = this;

    return menuData.map(menuItem => {
      if (!menuItem.children) {
        return (
          <div key={uuid.v4()}>
            <Link to={menuItem.link}>
              <li key={uuid.v4()} className={classes.anchor}>
                {menuItem.title}
              </li>
            </Link>
          </div>
        );
      }
      return (
        <div key={uuid.v4()}>
          <ul className={classes.unorganisedLi}>
            <li>
              <button
                className={classes.anchor}
                type="button"
                onClick={() => this.handleClick(menuItem.title)}
              >
                {menuItem.title}
                {state[menuItem.title] ? (
                  <ExpandLess className={classes.expandIconStyle} />
                ) : (
                  <ExpandMore className={classes.expandIconStyle} />
                )}
              </button>
            </li>
          </ul>
          <Collapse in={state[menuItem.title]} timeout="auto" unmountOnExit>
            <ul className={classes.unorganisedLi}>
              {this.handler(menuItem.children)}
            </ul>
          </Collapse>
        </div>
      );
    });
  }

  render() {
    const { classes, menuData } = this.props;

    return (
      <List className={classes.menuContainer}>{this.handler(menuData)}</List>
    );
  }
}

CollapsibleList.propTypes = {
  classes: PropTypes.shape({
    menuContainer: PropTypes.string.isRequired,
    anchor: PropTypes.string.isRequired
  }).isRequired,
  menuData: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default withStyles(styles)(CollapsibleList);
