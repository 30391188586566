import * as _ from 'lodash';
import uuid from 'uuid';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Tab from '@material-ui/core/Tab';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Flag from 'react-world-flags';
import LanguageButton from '../components/navbar/LanguageButton';

import navbarLinkParser from '../utils/navbar-link-parser';
import Container from './Container';
import Link from '../components/link/Link';
import PopperMenu from '../components/menus/PopperMenu';
import CollapsibleMenu from '../components/menus/CollapsibleMenu';

const styles = theme => ({
  logoImage: {
    height: '32px',
    [theme.breakpoints.up('xs')]: {
      'margin-left': '12px'
    },
    [theme.breakpoints.up('md')]: {
      'margin-left': 'unset'
    }
  },
  logoText: {
    'margin-right': 'auto',
    'margin-left': '16px',
    'text-decoration': 'none',
    color: 'inherit',
    '& h1': {
      'font-weight': '400',
      'user-select': 'none'
    }
  },
  drawerList: {
    'min-width': 250
  },
  tabRoot: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '95px'
    },
    '@media (min-width:1290px)': {
      minWidth: '120px'
    }
  },
  tabsRoot: {
    overflow: 'unset'
  },
  tabsFixed: {
    overflowX: 'unset'
  },
  activeLinkStyle: {
    color: theme.palette.secondary.dark,
    borderBottom: `2px solid ${theme.palette.secondary.dark}`
  }
});

class Navbar extends Component {
  state = {
    isDrawerOpen: false,
    linksForAllLanguages: []
  };

  componentDidMount() {
    const { data } = this.props;
    const dataClone = _.cloneDeep(data);
    const { allPrismicNavbar } = dataClone;
    const { nodes } = allPrismicNavbar;
    const parsedLinksPerLanguage = [];

    nodes.forEach(dataForLang => {
      const { lang, data: linksForLang } = dataForLang;
      const { logo_image, logo_text, level_1, level_2, level_3 } = linksForLang;
      const parsedLinks = navbarLinkParser({ level_1, level_2, level_3 });

      parsedLinksPerLanguage.push({
        lang,
        logo_image,
        logo_text,
        links: parsedLinks
      });
    });

    this.setState({ linksForAllLanguages: parsedLinksPerLanguage });
  }

  openDrawer = () => this.setState({ isDrawerOpen: true });

  closeDrawer = () => this.setState({ isDrawerOpen: false });

  handleLocale = (locale, flag) => {
    const { localeContext } = this.props;
    const { changeLocale } = localeContext;
    changeLocale(locale, flag);
  };

  render() {
    const { classes, localeContext, pathname } = this.props;
    const { isDrawerOpen, linksForAllLanguages } = this.state;

    const { locale, flag } = localeContext;

    const foundLinksForLanguage = linksForAllLanguages.find(
      ({ lang }) => lang === locale
    );

    if (!foundLinksForLanguage) {
      return null;
    }

    const { logo_image, logo_text, links } = foundLinksForLanguage;

    return (
      <AppBar position="sticky">
        <Container>
          <Toolbar variant="dense" disableGutters>
            {/* Logo image */}
            <Link to="/" className={classes.logoImage}>
              <img src={logo_image.url} alt={logo_image.alt} />
            </Link>

            {/* Logo text */}
            <Hidden xsDown>
              <Link to="/" className={classes.logoText}>
                <Typography variant="h6" component="h1">
                  {logo_text}
                </Typography>
              </Link>
            </Hidden>

            {/* Links */}
            <Hidden mdDown>
              {links &&
                links.map(pageLink => {
                  if (!pageLink.children) {
                    return (
                      <Tab
                        key={uuid.v4()}
                        component={Link}
                        label={pageLink.title}
                        to={pageLink.link}
                        activeClassName={classes.activeLinkStyle}
                        partiallyActive
                        disableRipple
                        classes={{
                          root: classes.tabRoot
                        }}
                      />
                    );
                  }
                  return (
                    <PopperMenu
                      key={uuid.v4()}
                      trigger={
                        <Tab
                          label={pageLink.title}
                          className={
                            pathname.includes(pageLink.route)
                              ? classes.activeLinkStyle
                              : ''
                          }
                          classes={{
                            root: classes.tabRoot
                          }}
                          disableRipple
                        />
                      }
                    >
                      <CollapsibleMenu menuData={pageLink.children} />
                    </PopperMenu>
                  );
                })}

              {/* Lang */}
              <PopperMenu
                key={uuid.v4()}
                trigger={
                  <Tab
                    label={
                      <Flag
                        className={classes.flagPosition}
                        code={flag}
                        height="20"
                      />
                    }
                    textColor="secondary"
                    classes={{
                      root: classes.tabRoot
                    }}
                    disableRipple
                  />
                }
              >
                <LanguageButton
                  locale={locale}
                  pathname={pathname}
                  handleLocale={this.handleLocale}
                />
              </PopperMenu>
            </Hidden>

            {/* Drawer for mobile and tablet screens */}
            <Hidden lgUp>
              <span style={{ flex: 1 }} />
              <IconButton aria-label="Menu" onClick={this.openDrawer}>
                <MenuIcon style={{ color: '#fff' }} />
              </IconButton>
              <Drawer
                open={isDrawerOpen}
                onClose={this.closeDrawer}
                anchor="right"
              >
                <div className={classes.drawerList}>
                  <CollapsibleMenu menuData={links} />
                  <PopperMenu
                    key={uuid.v4()}
                    trigger={
                      <ListItem button divider>
                        <ListItemText inset>
                          <Flag code={flag} height="20" />
                        </ListItemText>
                      </ListItem>
                    }
                  >
                    <LanguageButton
                      locale={locale}
                      pathname={pathname}
                      handleLocale={this.handleLocale}
                    />
                  </PopperMenu>
                </div>
              </Drawer>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  pathname: PropTypes.string.isRequired,
  localeContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
    changeLocale: PropTypes.func.isRequired
  }).isRequired,
  classes: PropTypes.shape({
    logoImage: PropTypes.string.isRequired,
    logoText: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    allPrismicNavbar: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
};

const NavbarWithStyles = withStyles(styles)(Navbar);

export default props => (
  <StaticQuery
    query={graphql`
      query Navbar {
        allPrismicNavbar {
          nodes {
            lang
            data {
              logo_image {
                alt
                copyright
                url
              }
              logo_text
              level_1 {
                title
                link
                route
              }
              level_2 {
                parent
                title
                link
              }
              level_3 {
                parent
                title
                link
              }
            }
          }
        }
      }
    `}
    render={data => <NavbarWithStyles data={data} {...props} />}
  />
);
