import * as _ from 'lodash';
import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Link from '../components/link/Link';
import submitEmail from '../utils/submit-email';

import navbarLinkParser from '../utils/navbar-link-parser';
import Container from './Container';
import Accordion from '../components/accordion-menu/Accordion';

const inputStyle = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: '2px solid #fff'
        },
        '&&&&:before': { borderBottom: '1px solid #fff' },
        '&&&&:after': { borderBottom: '1px solid #fff' }
      }
    }
  }
});

const styles = theme => ({
  container: {
    padding: '48px 0',
    'background-color': theme.palette.primary.main
  },
  blockStart: {
    [theme.breakpoints.down('sm')]: {
      'text-align': 'center'
    },
    [theme.breakpoints.up('sm')]: {
      'text-align': 'start'
    },
    'text-align': 'left'
  },
  blockCenter: {
    [theme.breakpoints.down('sm')]: {
      'text-align': 'center'
    },
    [theme.breakpoints.up('sm')]: {
      'text-align': theme.spacing(1)
    },
    'text-align': 'center'
  },
  blockEnd: {
    [theme.breakpoints.down('sm')]: {
      'text-align': 'center'
    },
    [theme.breakpoints.up('sm')]: {
      'text-align': 'end'
    },
    'text-align': 'right'
  },
  sectionTitle: {
    color: 'white'
  },
  sectionLink: {
    margin: theme.spacing(1, 0),
    color: '#b2dfde',
    'text-decoration': 'none',
    '&:hover': {
      color: '#ffffff'
    }
  },
  anchor: {
    color: '#b2dfde',
    'text-decoration': 'none',
    '&:hover': {
      color: '#ffffff'
    }
  },
  button: {
    margin: theme.spacing(1, 0),
    minWidth: '120px',
    height: '35px',
    borderRadius: '4px',
    color: 'white',
    border: '0px transparent',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'none',
    background: '#3C5A99',
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
      background: '#213A6F'
    }
  },
  iconText: {
    color: '#fff'
  },
  textFieldLabel: {
    color: '#fff'
  },
  focusedTextFieldLabel: {
    color: '#b2dfde !important'
  },
  subButtonPosition: {
    marginTop: '6px'
  },
  inputWidth: {
    width: '60%'
  }
});

const sanitizeErrorMessage = message => {
  let textOnly = message.replace(/<(?:.|\n)*?>/gm, '');
  if (textOnly.includes('already subscribed')) {
    textOnly = 'This email is already subscribed to our list';
  }

  return textOnly;
};

class Footer extends React.Component {
  state = {
    isLoading: false,
    isSubmitted: false,
    error: false,
    errorMsg: '',
    linksForAllLanguages: [],
    footerContent: []
  };

  componentDidMount() {
    const { data } = this.props;
    const dataClone = _.cloneDeep(data);
    const { allPrismicNavbar } = dataClone;
    const { nodes } = allPrismicNavbar;
    const parsedLinksPerLanguage = [];
    const footerText = [];

    nodes.forEach(dataForLang => {
      const { lang, data: linksForLang } = dataForLang;
      const {
        level_1,
        level_2,
        level_3,
        center_column_heading,
        input_label,
        left_column_heading,
        right_column_heading,
        submit_button_label
      } = linksForLang;

      const parsedLinks = navbarLinkParser({ level_1, level_2, level_3 });

      parsedLinksPerLanguage.push({
        lang,
        links: parsedLinks
      });

      footerText.push({
        lang,
        center_column_heading,
        input_label,
        left_column_heading,
        right_column_heading,
        submit_button_label
      });
    });

    this.setState({
      linksForAllLanguages: parsedLinksPerLanguage
    });
    this.setState({ footerContent: footerText });
  }

  showError = message =>
    this.setState({
      errorMsg: sanitizeErrorMessage(message),
      error: true
    });

  handleSubmit = async event => {
    try {
      event.preventDefault();
      this.setState({
        isLoading: true
      });
      const formDetails = {
        email: event.target.email.value,
        newsletter: 'Абониране за нашия бюлетин'
      };

      const { result } = await submitEmail(formDetails);

      if (result === 'success') {
        this.setState({ isSubmitted: true, isLoading: false });
      } else {
        this.showError(result);
        this.setState({ isSubmitted: false });
      }
    } catch (err) {
      this.setState({ isSubmitted: false });
      this.showError(err.message || 'Something went wrong!');
    }
  };

  render() {
    const { classes, localeContext } = this.props;
    const {
      isLoading,
      errorMsg,
      error,
      linksForAllLanguages,
      footerContent,
      isSubmitted
    } = this.state;

    const { locale } = localeContext;

    const foundLinksForLanguage = linksForAllLanguages.find(
      ({ lang }) => lang === locale
    );

    const foundFooterContentForLanguage = footerContent.find(
      ({ lang }) => lang === locale
    );

    if (!foundLinksForLanguage || !foundFooterContentForLanguage) {
      return null;
    }

    const {
      center_column_heading,
      input_label,
      left_column_heading,
      right_column_heading,
      submit_button_label
    } = foundFooterContentForLanguage;

    const successMsg = 'Успешно се абонирахте за нашия бюлетин!';

    const { links } = foundLinksForLanguage;

    const firstColumnLinks = links.slice(0, 3);
    const secondColumnLinks = links.slice(3, 6);

    return (
      <Container className={classes.container}>
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="stretch"
        >
          <Grid
            item
            container
            direction="column"
            xs={12}
            lg
            sm={3}
            className={classes.blockStart}
          >
            <Typography
              className={classes.sectionTitle}
              variant="h6"
              gutterBottom
            >
              {left_column_heading}
            </Typography>

            {firstColumnLinks &&
              firstColumnLinks.map(pageLink => {
                if (!pageLink.children) {
                  return (
                    <Typography
                      key={uuid.v4()}
                      className={classes.sectionLink}
                      variant="subtitle1"
                    >
                      <Link to={pageLink.link} className={classes.anchor}>
                        {pageLink.title}
                      </Link>
                    </Typography>
                  );
                }

                return (
                  <Typography key={uuid.v4()} variant="subtitle1">
                    <Accordion
                      title={pageLink.title}
                      menuData={pageLink.children}
                    />
                  </Typography>
                );
              })}
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={12}
            lg
            sm
            className={classes.blockCenter}
          >
            <Typography
              className={classes.sectionTitle}
              variant="h6"
              gutterBottom
            >
              {center_column_heading}
            </Typography>
            {secondColumnLinks &&
              secondColumnLinks.map(pageLink => (
                <Typography
                  key={uuid.v4()}
                  className={classes.sectionLink}
                  variant="subtitle1"
                >
                  <Link className={classes.anchor} to={pageLink.link}>
                    {pageLink.title}
                  </Link>
                </Typography>
              ))}
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            lg
            sm={3}
            className={classes.blockEnd}
          >
            <Grid item>
              <Link
                className={classes.anchor}
                target="_blank"
                to="https://www.facebook.com/Примавет-София-ООД-114955329953185/"
              >
                <Button variant="contained" className={classes.button}>
                  <Typography
                    className={classes.iconText}
                    variant="body1"
                    gutterBottom
                  >
                    Facebook
                  </Typography>
                </Button>
              </Link>
            </Grid>
            <Typography className={classes.sectionTitle} variant="h6">
              {right_column_heading}
            </Typography>

            {isSubmitted ? (
              <Typography className={classes.sectionTitle} variant="h6">
                {successMsg}
              </Typography>
            ) : (
              <form onSubmit={this.handleSubmit}>
                <Grid item>
                  <MuiThemeProvider theme={inputStyle}>
                    <TextField
                      label={input_label}
                      name="email"
                      error={error}
                      className={classes.inputWidth}
                      helperText={errorMsg}
                      type="email"
                      required
                      autoComplete="email"
                      margin="none"
                      InputLabelProps={{
                        classes: {
                          root: classes.textFieldLabel,
                          focused: classes.focusedTextFieldLabel
                        }
                      }}
                      InputProps={{
                        style: {
                          color: 'white'
                        }
                      }}
                    />
                  </MuiThemeProvider>
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <CircularProgress size={36} color="secondary" />
                  ) : (
                    <Button
                      type="submit"
                      className={classes.subButtonPosition}
                      variant="contained"
                      color="secondary"
                    >
                      {submit_button_label}
                    </Button>
                  )}
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

Footer.propTypes = {
  localeContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    changeLocale: PropTypes.func.isRequired
  }).isRequired,
  data: PropTypes.shape({
    allPrismicNavbar: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    }).isRequired
  }).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    blockStart: PropTypes.string.isRequired,
    blockCenter: PropTypes.string.isRequired,
    blockEnd: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    sectionLink: PropTypes.string.isRequired,
    anchor: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    textFieldLabel: PropTypes.string.isRequired,
    focusedTextFieldLabel: PropTypes.string.isRequired,
    subButtonPosition: PropTypes.string.isRequired
  }).isRequired
};

const FooterWithStyles = withStyles(styles)(Footer);

export default props => (
  <StaticQuery
    query={graphql`
      query Footer {
        allPrismicNavbar {
          nodes {
            lang
            data {
              logo_image {
                alt
                copyright
                url
              }
              logo_text
              level_1 {
                title
                link
              }
              level_2 {
                parent
                title
                link
              }
              level_3 {
                parent
                title
                link
              }
              left_column_heading
              center_column_heading
              right_column_heading
              input_label
              submit_button_label
            }
          }
        }
      }
    `}
    render={data => <FooterWithStyles data={data} {...props} />}
  />
);
