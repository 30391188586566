import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Chat from '@material-ui/icons/Chat';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  fab: {
    position: 'fixed',
    zIndex: 2,
    bottom: theme.spacing(6),
    right: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    [theme.breakpoints.only('sm')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4)
    }
  }
});

const FabButton = ({ classes, handleClick }) => (
  <Tooltip title="Обратна Връзка" aria-label="Survey" placement="left">
    <Fab className={classes.fab} onClick={handleClick} color="secondary">
      <Chat />
    </Fab>
  </Tooltip>
);

FabButton.propTypes = {
  classes: PropTypes.shape({ fab: PropTypes.string }).isRequired,
  handleClick: PropTypes.func.isRequired
};

export default withStyles(styles)(FabButton);
