import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import { LocaleContext } from '../App';
import Seo from './Seo';
import Navbar from './Navbar';
import Footer from './Footer';
import FootNote from './FootNote';
import Survey from './Survey';

// Height calculation for dense Navbar
const styles = theme => ({
  calcMinHeight: {
    minHeight: '100vh',
    marginTop: '-48px',
    '& > div:first-child': {
      marginTop: '48px',
      background: 'url(/images/hero_background.png)',
      'background-size': 'cover',
      [theme.breakpoints.down('sm')]: {
        'background-size': 'contain',
        backgroundRepeat: 'no-repeat'
      }
    },
    '& > div:not(:first-child):not(:last-child):not(:nth-last-child(2))': {
      margin: theme.spacing(2, 0)
    }
  }
});

const Page = ({ children, classes, seo }) => (
  <LocaleContext.Consumer>
    {localeContext => (
      <React.Fragment>
        <Seo {...seo} />
        <Navbar localeContext={localeContext} pathname={seo.pathname} />
        <Survey />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          className={classes.calcMinHeight}
        >
          {children}
        </Grid>
        <Footer localeContext={localeContext} />
        <FootNote localeContext={localeContext} />
      </React.Fragment>
    )}
  </LocaleContext.Consumer>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({ calcMinHeight: PropTypes.string }).isRequired,
  seo: PropTypes.shape(Seo.propTypes).isRequired
};

export default withStyles(styles)(Page);
