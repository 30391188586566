import uuid from 'uuid';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Link from '../link/Link';

const styles = () => ({
  menuContainer: {
    paddingTop: 0,
    paddingBottom: 0
  },
  menuArrow: {
    color: 'black',
    marginLeft: 'auto'
  },
  nestedMenuItem: {},
  nestedMenuItems: {
    '& > div > div > div > a > div ': {
      paddingLeft: '35px'
    },
    '& > div > div > div >  div ': {
      paddingLeft: '35px'
    }
  }
});

class CollapsibleMenu extends Component {
  state = {};

  handleClick(item) {
    this.setState(prevState => ({ [item]: !prevState[item] }));
  }

  handler(menuData) {
    const { classes } = this.props;
    const { state } = this;
    return menuData.map(menuItem => {
      if (!menuItem.children) {
        return (
          <div key={uuid.v4()}>
            <Link to={menuItem.link}>
              <ListItem key={uuid.v4()} button>
                <ListItemText inset primary={menuItem.title} />
              </ListItem>
            </Link>
          </div>
        );
      }
      return (
        <div key={uuid.v4()}>
          <ListItem onClick={() => this.handleClick(menuItem.title)} button>
            <ListItemText primary={menuItem.title} inset />
            {state[menuItem.title] ? (
              <ExpandLess className={classes.menuArrow} />
            ) : (
              <ExpandMore className={classes.menuArrow} />
            )}
          </ListItem>
          <Collapse
            in={state[menuItem.title]}
            className={classes.nestedMenuItems}
            timeout="auto"
            unmountOnExit
          >
            {this.handler(menuItem.children)}
          </Collapse>
        </div>
      );
    });
  }

  render() {
    const { classes, menuData } = this.props;

    return (
      <List className={classes.menuContainer}>{this.handler(menuData)}</List>
    );
  }
}

CollapsibleMenu.propTypes = {
  classes: PropTypes.shape({
    menuContainer: PropTypes.string.isRequired,
    menuArrow: PropTypes.string.isRequired
  }).isRequired,
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          to: PropTypes.string
        })
      )
    })
  ).isRequired
};

export default withStyles(styles)(CollapsibleMenu);
