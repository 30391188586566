export default navbarLinksFromPrismic => {
  const highLevelLinks = [...navbarLinksFromPrismic.level_1];
  const midLevelLinks = [...navbarLinksFromPrismic.level_2];
  const lowLevelLinks = [...navbarLinksFromPrismic.level_3];

  lowLevelLinks.map(link => {
    const parent = midLevelLinks.find(l => l.title === link.parent);
    if (parent) {
      if (parent.children) {
        parent.children.push(link);
      } else {
        parent.children = [link];
      }
    }
  });

  midLevelLinks.map(link => {
    const parent = highLevelLinks.find(l => l.title === link.parent);
    if (parent) {
      if (parent.children) {
        parent.children.push(link);
      } else {
        parent.children = [link];
      }
    }
  });

  return highLevelLinks;
};
