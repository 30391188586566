/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link as GatsbyLink } from 'gatsby';

import { LocaleContext } from '../../App';

const styles = () => ({
  textDecorationNone: {
    color: 'unset',
    'text-decoration': 'none'
  },
  textDecorationNoneExternal: {
    'text-decoration': 'none'
  }
});

const localizedHref = (locale, originalLink) =>
  locale === 'bg' ? originalLink : `/${locale.substring(0, 2)}${originalLink}`;

class Link extends React.Component {
  render() {
    const { children, classes, to, ...rest } = this.props;
    const isInternalLink = /^\/(?!\/)/.test(to);

    return (
      <LocaleContext.Consumer>
        {localeContext => {
          if (isInternalLink) {
            return (
              <GatsbyLink
                to={localizedHref(localeContext.locale, to)}
                className={classes.textDecorationNone}
                {...rest}
              >
                {children}
              </GatsbyLink>
            );
          }
          return (
            <a
              href={to}
              className={classes.textDecorationNoneExternal}
              {...rest}
            >
              {children}
            </a>
          );
        }}
      </LocaleContext.Consumer>
    );
  }
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    textDecorationNone: PropTypes.string,
    textDecorationNoneExternal: PropTypes.string
  }).isRequired,
  to: PropTypes.string.isRequired
};

export default withStyles(styles)(Link);
