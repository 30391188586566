import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const styles = theme => ({
  popper: {
    minWidth: 250,
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  mobilePaper: {
    [theme.breakpoints.down('md')]: {
      left: '5px',
      position: 'relative',
      boxShadow: 'unset'
    }
  }
});

class PopperMenu extends Component {
  state = {
    open: false,
    anchorEl: null,
    arrowRef: null
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    const { anchorEl } = this.state;

    if (anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleButtonRef = node => {
    this.setState({
      anchorEl: node
    });
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node
    });
  };

  render() {
    const { children, classes, placement, trigger: Trigger } = this.props;
    const { open, anchorEl, arrowRef } = this.state;

    return (
      <React.Fragment>
        {React.cloneElement(Trigger, {
          ...Trigger.props,
          buttonRef: this.handleButtonRef,
          onClick: this.handleToggle
        })}

        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorEl}
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef
            }
          }}
          placement={placement}
          disablePortal
        >
          <span className={classes.arrow} ref={this.handleArrowRef} />
          <Paper className={classes.mobilePaper}>
            <ClickAwayListener onClickAway={this.handleClose}>
              {children}
            </ClickAwayListener>
          </Paper>
        </Popper>
      </React.Fragment>
    );
  }
}

PopperMenu.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    popper: PropTypes.string.isRequired,
    arrow: PropTypes.string.isRequired
  }).isRequired,
  placement: PropTypes.string,
  trigger: PropTypes.node.isRequired
};

PopperMenu.defaultProps = {
  placement: 'bottom'
};

export default withStyles(styles)(PopperMenu);
