import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FabButton from '../components/fab-button/FabButton';
import submitEmail from '../utils/submit-email';

const styles = theme => ({
  paperStyle: {
    [theme.breakpoints.only('xs')]: {
      padding: '15px 1px'
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5)
    }
  },
  dialogBackground: {
    'background-color': 'rgba(0, 0, 0, 0.7)'
  },
  hidden: {
    display: 'none'
  },
  paperMargin: {
    [theme.breakpoints.only('xs')]: {
      margin: '5px'
    }
  }
});

class Survey extends React.Component {
  state = {
    open: false,
    successMsg: '',
    errorMsg: '',
    isSubmitted: false,
    isLoading: false,
    satisfaction: '',
    quality: '',
    qualityToPrice: '',
    consultants: ''
  };

  showSuccess = () =>
    this.setState({
      successMsg: 'Благодарим Ви за обратната връзка!'
    });

  showError = err =>
    this.setState({
      errorMsg: err
    });

  handleSatisfactionButtonChange = event => {
    this.setState({ satisfaction: event.target.value });
  };

  handleQualityButtonChange = event => {
    this.setState({ quality: event.target.value });
  };

  handleQualityToPriceButtonChange = event => {
    this.setState({ qualityToPrice: event.target.value });
  };

  handleCustomerSupportButtonChange = event => {
    this.setState({ consultants: event.target.value });
  };

  handleSubmit = async event => {
    try {
      event.preventDefault();
      this.setState({ isLoading: true });

      const formDetails = {
        name: event.target.name.value,
        email: event.target.email.value,
        message: event.target.message.value,
        phone: event.target.phone.value,
        satisfaction: event.target.satisfaction.value,
        quality: event.target.quality.value,
        quality_to_price: event.target.qualityToPrice.value,
        consultants_satisfaction: event.target.consultants.value
      };

      const { result } = await submitEmail(formDetails);

      if (result === 'success') {
        this.setState({ isSubmitted: true, isLoading: false });
        this.showSuccess();
      } else {
        this.showError(result);
        this.setState({ isSubmitted: false });
      }
    } catch (err) {
      this.setState({ isSubmitted: false });
      this.showError(err.message || 'Something went wrong!');
    }
  };

  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  render() {
    const { classes } = this.props;
    const {
      open,
      successMsg,
      isLoading,
      isSubmitted,
      errorMsg,
      satisfaction,
      quality,
      qualityToPrice,
      consultants
    } = this.state;

    return (
      <React.Fragment>
        <FabButton handleClick={this.handleOpen} />
        <Dialog
          open={open}
          onClose={this.handleClose}
          classes={{
            root: classes.dialogBackground,
            paper: classes.paperMargin
          }}
        >
          <Paper className={classes.paperStyle} elevation={8}>
            <form onSubmit={this.handleSubmit}>
              <Typography
                variant="h6"
                component="h3"
                align="center"
                gutterBottom
              >
                {successMsg ||
                  `Уважаеми клиенти, С настоящата анкетна карта "Примавет-София" ООД цели проучване и изследване мнението на крайните потребители на своите продукти. Получената информация ще ни даде възможност да направим реална оценка и анализ на Вашата удовлетвореност от произвежданите от нас продукти. Стремежът ни е насочен към предлагането на високоефективни продукти на всеки един от Вас.`}
              </Typography>
              <div className={successMsg ? classes.hidden : ''}>
                <DialogContent>
                  <Typography variant="subtitle1" component="h4" align="center">
                    Предварително благодарим за отделеното време и внимание
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    align="center"
                    gutterBottom
                  >
                    &ldquo;Примавет-София&ldquo; ООД се ангажира със запазването
                    на поверителността и защитата на личните данни и информация
                    на участниците в проучването
                  </Typography>

                  <TextField
                    label="Име"
                    name="name"
                    type="text"
                    required
                    fullWidth
                    inputProps={{
                      minLength: 2,
                      maxLength: 250
                    }}
                  />

                  <TextField
                    label="Емайл"
                    name="email"
                    type="email"
                    required
                    fullWidth
                    inputProps={{
                      minLength: 3,
                      maxLength: 120
                    }}
                  />

                  <TextField
                    label="Телефон"
                    name="phone"
                    type="tel"
                    required
                    fullWidth
                    inputProps={{
                      minLength: 3,
                      maxLength: 120
                    }}
                  />
                  <RadioGroup
                    name="satisfaction"
                    value={satisfaction}
                    onChange={this.handleSatisfactionButtonChange}
                  >
                    <Box py={2}>
                      <Typography
                        variant="subtitle1"
                        component="h4"
                        align="center"
                      >
                        Как бихте оценили удовлетвореността си от произвежданите
                        от нас продукти?
                      </Typography>
                    </Box>
                    <Divider />
                    <FormControlLabel
                      value="Напълно съм удовлетворен"
                      control={<Radio />}
                      label="Напълно съм удовлетворен"
                    />
                    <FormControlLabel
                      value="По-скоро съм удовлетворен"
                      control={<Radio />}
                      label="По-скоро съм удовлетворен"
                    />
                    <FormControlLabel
                      value="Не съм удовлетворен"
                      control={<Radio />}
                      label="Не съм удовлетворен"
                    />
                  </RadioGroup>

                  <RadioGroup
                    name="quality"
                    value={quality}
                    onChange={this.handleQualityButtonChange}
                  >
                    <Box py={2}>
                      <Typography
                        variant="subtitle1"
                        component="h4"
                        align="center"
                      >
                        Как бихте оценили качеството на произвежданите от нас
                        продукти?
                      </Typography>
                    </Box>
                    <Divider />
                    <FormControlLabel
                      value="Отлично"
                      control={<Radio />}
                      label="Отлично"
                    />
                    <FormControlLabel
                      value="Много добро"
                      control={<Radio />}
                      label="Много добро"
                    />
                    <FormControlLabel
                      value="Добро"
                      control={<Radio />}
                      label="Добро"
                    />
                    <FormControlLabel
                      value="Задоволително"
                      control={<Radio />}
                      label="Задоволително"
                    />
                    <FormControlLabel
                      value="Лошо"
                      control={<Radio />}
                      label="Лошо"
                    />
                  </RadioGroup>

                  <RadioGroup
                    name="qualityToPrice"
                    value={qualityToPrice}
                    onChange={this.handleQualityToPriceButtonChange}
                  >
                    <Box py={2}>
                      <Typography
                        variant="subtitle1"
                        component="h4"
                        align="center"
                      >
                        Как бихте оценили съотношението качество-цена на
                        произвежданите от нас продукти?
                      </Typography>
                    </Box>
                    <Divider />
                    <FormControlLabel
                      value="Напълно съм удовлетворен"
                      control={<Radio />}
                      label="Напълно съм удовлетворен"
                    />
                    <FormControlLabel
                      value="По-скоро съм удовлетворен"
                      control={<Radio />}
                      label="По-скоро съм удовлетворен"
                    />
                    <FormControlLabel
                      value="Не съм удовлетворен"
                      control={<Radio />}
                      label="Не съм удовлетворен"
                    />
                  </RadioGroup>

                  <RadioGroup
                    name="consultants"
                    value={consultants}
                    onChange={this.handleCustomerSupportButtonChange}
                  >
                    <Box py={2}>
                      <Typography
                        variant="subtitle1"
                        component="h4"
                        align="center"
                      >
                        Как бихте оценили компетентността на консултантите, от
                        които закупувате произвежданите от нас продукти?
                      </Typography>
                    </Box>
                    <Divider />
                    <FormControlLabel
                      value="Отлично"
                      control={<Radio />}
                      label="Отлично"
                    />
                    <FormControlLabel
                      value="Много добро"
                      control={<Radio />}
                      label="Много добро"
                    />
                    <FormControlLabel
                      value="Добро"
                      control={<Radio />}
                      label="Добро"
                    />
                    <FormControlLabel
                      value="Задоволително"
                      control={<Radio />}
                      label="Задоволително"
                    />
                    <FormControlLabel
                      value="Лошо"
                      control={<Radio />}
                      label="Лошо"
                    />
                  </RadioGroup>

                  <TextField
                    label="Вашите препоръки"
                    name="message"
                    helperText={errorMsg}
                    type="text"
                    multiline
                    required
                    rows="4"
                    fullWidth
                    inputProps={{
                      minLength: 60,
                      maxLength: 5000
                    }}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    align="center"
                    gutterBottom
                  >
                    Примавет София ООД е лицензиран администратор на данни.
                    Данните ви ще бъдат използвани единствено във връзка с
                    отговор на вашето съобщение
                  </Typography>
                </DialogContent>
              </div>
              <DialogActions>
                {!isLoading && (
                  <Button
                    onClick={this.handleClose}
                    variant={successMsg ? 'contained' : 'outlined'}
                    color="primary"
                  >
                    {successMsg ? 'Изпратено' : 'Oтказ'}
                  </Button>
                )}
                {!isSubmitted && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      'Изпрати'
                    )}
                  </Button>
                )}
              </DialogActions>
            </form>
          </Paper>
        </Dialog>
      </React.Fragment>
    );
  }
}

Survey.propTypes = {
  classes: PropTypes.shape({
    dialogBackground: PropTypes.string.isRequired,
    hidden: PropTypes.string.isRequired,
    paperStyle: PropTypes.string.isRequired,
    paperMargin: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles)(Survey);
